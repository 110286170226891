import React from "react"
import Helmet from "react-helmet"

import video from "../styles/video.module.css"
import ReactPlayer from "react-player"


export default () => (
    <div className={video.noclicks}>
        <Helmet>
            <title>powered by Gatsby | clean and refreshing.</title>
        </Helmet>
        <ReactPlayer
            url='https://www.youtube.com/watch?v=eax7GqQ3Uj4'
            playing={true}
            loop={true}
            controls={false}
            width="100%"
            height="100vh"
            config={{ youtube: { playerVars: { disablekb: 1 } } }}  // no keyboard interactions.
        />
    </div>
)
